<template>
  <static-page-display :page="page" />
</template>
<script>
import StaticPageDisplay from "../components/admin/static-pages/StaticPageDisplay.vue";
export default {
  components: {
    StaticPageDisplay,
  },
  computed: {
    page() {
      return this.$store.getters["Pages/current"];
    },
    link() {
      return this.$route.params.link;
    },
  },
  watch: {
    link() {
      this.fetchPage();
    },
  },
  methods: {
    async fetchPage() {
      await this.$store.dispatch("Pages/fetchByLink", this.link);
      window.scrollTo(0, 0);
    },
  },
  async created() {
    await this.fetchPage();
  },
};
</script>
